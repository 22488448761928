<template>
	<BaseLayout>
		<Toolbar @clickLeft="isHistory ? toggleHistory() : $router.push('/account')" @clickRight="toggleHistory">
			<template v-slot:start>
				<Icon class="h-5 w-5" icon="angleLeft" />
			</template>
			<p>{{ isHistory ? $t('myOrder.completedOrder') : $t('myOrder.myOrder') }}</p>
			<template v-slot:end>
				<p v-if="!isHistory">{{ $t('myOrder.orderHistory') }}</p>
			</template>
		</Toolbar>
		<div class="p-5">
			<div v-for="order in orders" :key="order.id" class="mb-5 flex flex-col gap-5 rounded-xl bg-light-blue p-5" @click="setCurrentSale(order)">
				<div class="flex items-end justify-between text-primary">
					<div class="whitespace-pre text-base">{{ $t('myOrder.orderNumber') }}{{ order.referenceNumber }}</div>

					<div class="inline-flex h-7 max-w-max items-center rounded-full bg-primary px-1 text-xs text-white">
						{{ orderStatus(order.status) }}
					</div>
				</div>
				<div class="flex w-full overflow-y-scroll disable-scrollbars">
					<template v-for="product in order.products" :key="product.id">
						<div v-if="product.status !== 'CANCELED'" class="relative">
							<div class="mr-3 mt-2 h-16 w-16">
								<img class="mx-auto h-full w-full rounded-xl object-cover" :src="getProduct(product).imageUrls[0]" loading="lazy" />
							</div>
							<div class="absolute top-0 right-0 inline-flex h-5 w-5 items-center justify-center rounded-2xl bg-primary text-white">
								{{ product.orderedQuantity }}
							</div>
						</div>
					</template>
				</div>

				<div class="flex justify-end gap-5 text-sm text-secondary">
					<div>
						{{ $t('myOrder.orderItemsTotal')
						}}{{
							order.products.reduce((total, product) => {
								return total + product.orderedQuantity
							}, 0)
						}}{{ $t('myOrder.orderItemsUnite') }}
					</div>
					<div>{{ $t('myOrder.totalAmount') }}{{ order.grandTotal }}</div>
				</div>
			</div>
		</div>
	</BaseLayout>
</template>

<script>
import { storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
// import { useStore } from 'vuex'
import Toolbar from '@/components/Toolbar'
import { useI18n } from 'vue-i18n'
import { useSaleStore } from '@/store/sale'
import { useCatalogStore } from '@/store/catalog'

export default {
	components: {
		Toolbar,
	},

	setup() {
		const router = useRouter()
		// const store = useStore()
		const { sales } = storeToRefs(useSaleStore())
		const { getCurrentSale } = useSaleStore()
		const { getProduct } = useCatalogStore()

		const orders = computed(() => {
			const historyStatus = ['CANCELED', 'CLOSED']
			const status = ['OPEN', 'RECEIVED', 'DELIVERED', 'PACKED']
			if (isHistory.value) return sales.value.filter(o => historyStatus.includes(o.status)).sort((a, b) => b.createdAt - a.createdAt)
			return sales.value.filter(o => status.includes(o.status)).sort((a, b) => b.createdAt - a.createdAt)
		})
		const isHistory = ref(false)

		function toggleHistory() {
			isHistory.value = !isHistory.value
		}

		const { t } = useI18n({ useScope: 'global' })
		function orderStatus(status) {
			switch (status) {
				case 'OPEN':
					return t('orderStatus.PENDING')
				case 'RECEIVED':
					return t('orderStatus.RECEIVED')
				case 'ON_HOLD':
					return t('orderStatus.ON_HOLD')
				case 'CANCELLED':
					return t('orderStatus.CANCELLED')
				case 'CLOSED':
					return t('orderStatus.CLOSED')
				case 'PACKED':
					return t('orderStatus.PACKED')
				case 'DELIVERED':
					return t('orderStatus.DELIVERED')
			}
		}

		function setCurrentSale(order) {
			getCurrentSale(order.id)
			router.push(`/account/orders/${order.id}`)
		}

		return {
			sales,
			setCurrentSale,
			orders,
			getProduct,
			isHistory,
			toggleHistory,
			orderStatus,
		}
	},
}
</script>
